import Head from "next/head";
import Link from "next/link";
import useSWR from "swr";

const fetcher = (url) => fetch(url).then((res) => res.json());

function Home() {
  const { data: documents, error: documentsError } = useSWR(
    "/api/document",
    fetcher
  );
 
  if (documentsError) return "An error has occurred.";
  if (!documents) return "Loading...";

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2">
      <Head>
        <title>Dashboard</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <ul>
        {documents.map((doc) => (
          <li key={`${doc.name}_${doc.id}`}>
            Open document:&nbsp;
            <Link href={`/document/${doc.id}/edit`}>
              <a className="text-blue-600 underline">
                {doc.form.company.displayName} {doc.form.displayName} {doc.id}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Home;
